import React, { useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { device } from '../../../../utils/constants';
import useMenuHook from '../menu/MenuHook';
import MenuHome from './MenuHome';
import LogoComponent from './components/Logo';
import LayoutMenu from './layoutHome';

// const Container = styled.div`
//   width: 100vw;
//   height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: black;
//   // background-image: url("./img/plp06.png");
//   // background-repeat: no-repeat;
//   // background-size: cover;

//   span {
//     font-family: 'FuturaBold';
//     color: white;
//     font-size: 7vw;
//   }

//   @media ${device.mobile} {
//     background-size: cover;
//     .type-wrap {
//       span {
//         font-size: 10vw;
//       }
//     }
//   }
// `;

// const Body = styled.div`
//   width: 100vw;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: black;
//   flex-direction: column;

//   @media ${device.mobile} {
//     background-size: cover;
//     .type-wrap {
//       span {
//         font-size: 10vw;
//       }
//     }
//   }
// `;

export const Title = styled.div`
  color: white;
  font-size: 1.3vw;
  font-family: 'MontserratLight';
  margin-top: 1.4vw;
  text-align: ${(center) => (center ? 'center' : 'right')};

  @media ${device.mobile} {
    font-size: ${({ fs }) => fs ?? '6vw'};
    line-height: 1.1em;
  }
`;

export const SubTitle = styled.div`
  color: white;
  font-size: 1vw;
  font-family: 'MontserratBold';

  @media ${device.mobile} {
    margin-top: 1vw;
    font-size: 4vw;
  }
`;

const Home = () => {
  const { t } = useTranslation();
  const { lang } = useMenuHook();

  useEffect(() => {
    if (localStorage.getItem('persist:PLP')) {
      localStorage.removeItem('persist:PLP');
    }
  }, []);

  return (
    <LayoutMenu isLogoTop={false}>
      <Fade duration={2000}>
        <LogoComponent />
        <Title fs={lang == 'en' ? null : '5vw'}>{t('home.text1')}</Title>
        <SubTitle>{t('home.text2')}</SubTitle>
      </Fade>
      <MenuHome />
    </LayoutMenu>
  );
};

export default Home;
