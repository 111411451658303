import { saveArchivosService } from '../../services';

export const saveArchivosAction = async (
  data,
  { idPelicula, idBlog, idVideo, idVideoEN }
) => {
  const res = await saveArchivosService(data, {
    idPelicula,
    idBlog,
    idVideo,
    idVideoEN,
  });
  return res;
};
