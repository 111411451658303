import {
  deleteVideosService,
  getAllVideosService,
  saveVideosService,
} from '../../services';
import { GET_ALL_VIDEOS, SET_VIDEOS_ACTIVO } from '../types';
import { saveArchivosAction } from './archivos.action';

export const getAllVideosAction = async (dispatch, page) => {
  try {
    const res = await getAllVideosService({ page });
    dispatch({
      type: GET_ALL_VIDEOS,
      payload: res.data,
    });
    return res;
  } catch (error) {
    return false;
  }
};

export const saveVideosAction = async (
  dispatch,
  id,
  { data, file, fileEN }
) => {
  try {
    const res = await saveVideosService(id, data);

    if (res.status < 300 && file) {
      const resF = await saveArchivosAction(file, {
        idVideo: res.data.data.id,
      });
      if (res.status > 300) alert(resF.data.message);
    }
    if (res.status < 300 && fileEN) {
      const resF = await saveArchivosAction(fileEN, {
        idVideoEN: res.data.data.id,
      });
      if (res.status > 300) alert(resF.data.message);
    }

    alert(res.data.message);
    await getAllVideosAction(dispatch, null);
    return res.status < 300;
  } catch (e) {
    if (e.response.data) {
      alert(e.response.data.message);
    }
    console.log(e);
  }
};

export const setActivoVideosAction = async (dispatch, data) => {
  dispatch({
    type: SET_VIDEOS_ACTIVO,
    payload: data,
  });
};

export const deleteVideosAction = async (dispatch, id) => {
  const res = await deleteVideosService(id);
  await getAllVideosAction(dispatch, null);
  alert(res.message);
  return res;
};
